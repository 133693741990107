import "@babel/polyfill";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import CenterLoader from "~app/components/loaders/center";

import "~fontawesome";
import "~app/styles/antd-customization.less";
import "~app/styles/bulma-customization.sass";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import axios from "axios";

axios.default.interceptors.request.use((config) => {
    const {accessToken} = window;
    const newConfig = {...config};
    if (accessToken) {
        newConfig.headers.Authorization = `Bearer ${accessToken}`;
    }
    return newConfig;
});

Sentry.init({
    dsn: null,
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
});

const App = lazy(() => import("./app/index"));
const rootElement = document.getElementById("app");
ReactDOM.render(
    <Suspense fallback={<CenterLoader />}>
        <App />
  </Suspense>, rootElement
);
